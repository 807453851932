<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="card">
        <DataTable :value="generaldictsetlist" v-model:expandedRows="expandedRows" :lazy="false"
            responsiveLayout="scroll" stripedRows :scrollHeight="sHeight" :tableStyle="styleHeight" :loading="loading"
            :paginator="false">
            <template #header>
                <div class="grid">
                    <div class="col-3">
                        <div class="text-left">
                            <InputText id='searchNameInput' placeholder="名称" v-model="searchName" type="text" />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="text-right">
                            <Button label="查询" icon="pi pi-search" @click="loadData(1)" class="ml-auto" />
                        </div>
                    </div>
                </div>
            </template>
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="name" header="名称">
            </Column>
            <Column field="op" header="操作">
                <template #body="slotProps">
                    <Button label="添加" :loading="butLoading" @click="addgeneraldict(slotProps.data)"
                        class="p-button-link" />&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button label="保存" :loading="butLoading" @click="savegeneraldict(slotProps.data)"
                        class="p-button-link" />&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button label="删除" :loading="butLoading" @click="delgeneraldict(slotProps.data)"
                        class="p-button-link" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.rows" stripedRows v-model:editingRows="editingRows" :lazy="false"
                        editMode="cell" dataKey="id" v-model:selection="selectedRows" responsiveLayout="scroll"
                        :metaKeySelection="false" @cell-edit-complete="cellEditComplete($event,slotProps.data.rows)"
                        @row-edit-save="onRowEditSave($event,slotProps.data.rows)" @cell-edit-init="cellEdit">
                        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                        <Column v-for="item in slotProps.data.cols" :style="getColWidth(slotProps.data.cols)"
                            :key="item.name" :field="item.name" :header="item.label">
                            <template #editor="{ data }">
                                <InputText v-if="item.type!='editor'" v-model="data[item.name]" id="input2" autofocus />
                                <Editor v-if="item.type=='editor'" v-model="data[item.name]" editorStyle="height:80px">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                        </span>
                                    </template>
                                </Editor>
                            </template>
                            <template #body="{data}">
                                <label v-if="item.type!='editor'">{{data[item.name]}}</label>
                                <ScrollPanel v-if="item.type=='editor'" style="height:160px">
                                    <p v-if="item.type=='editor'" v-html="data[item.name]">
                                    </p>
                                </ScrollPanel>
                            </template>
                        </Column>
                        <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center">
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </div>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import Editor from 'primevue/editor';
    import requests from '../../service/newaxios';
    import * as Base64 from 'js-base64';
    import MessageTip from '../../components/Message';

    export default {
        setup() {
            const confirm = useConfirm();
            return {
                confirm,
            };
        },
        data() {
            return {
                newId: 2,
                expandedRows: ref([]),
                selectedData: ref(),
                searchName: ref(),
                loading: false,
                butLoading: false,
                generaldictsetlist: [],
                sHeight: '600px',
                styleHeight: '',
                editingRows: ref(),
                selectedRows: ref(),
            }
        },
        created() {
            MessageTip.initMsg();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.loadData(1);
        },
        methods: {
            getNewId() {
                var id = -1 * this.newId;
                this.newId = this.newId + 1;
                return id + '';
            },
            getColWidth(v_cols) {
                var w = 80 / v_cols.length;
                return "width:" + w + "%";
            },
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'name',
                        value: this.searchName,
                        operation: '',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{generaldictsetlist(where:" + JSON.stringify(listwhere) +
                    "){name categories data {rowid name colname colvalue coltype}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var retData = jsonData.data.generaldictsetlist;
                        this.generaldictsetlist = [];
                        for (var i = 0; i < retData.length; i++) {
                            var rowObj = {
                                name: retData[i]['name'],
                                categories: retData[i]['categories'],
                                rows: [],
                                cols: [],
                            };
                            for (var j = 0; j < retData[i]['data'].length; j++) {
                                var isAdd = true;
                                for (var k1 = 0; k1 < rowObj.cols.length; k1++) {
                                    if (rowObj.cols[k1]['name'] == retData[i]['data'][j]['colname']) {
                                        isAdd = false;
                                        break;
                                    }
                                }
                                if (isAdd) {
                                    rowObj.cols.push({
                                        label: retData[i]['data'][j]['name'],
                                        name: retData[i]['data'][j]['colname'],
                                        type: retData[i]['data'][j]['coltype']
                                    });
                                }
                            }
                            for (var j1 = 0; j1 < retData[i]['data'].length; j1++) {
                                var rowAdd = true;
                                for (var k2 = 0; k2 < rowObj.rows.length; k2++) {
                                    if (retData[i]['data'][j1]['rowid'] == rowObj.rows[k2]['id']) {
                                        rowAdd = false;
                                        break;
                                    }
                                }
                                if (rowAdd) {
                                    var rowData = {
                                        id: retData[i]['data'][j1]['rowid']
                                    };
                                    for (var k3 = 0; k3 < retData[i]['data'].length; k3++) {
                                        if (retData[i]['data'][j1]['rowid'] == retData[i]['data'][k3][
                                                'rowid'
                                            ]) {
                                            rowData[retData[i]['data'][k3]['colname']] = retData[i]['data'][k3][
                                                'colvalue'
                                            ];
                                        }
                                    }
                                    rowObj.rows.push(rowData);
                                }
                            }
                            this.generaldictsetlist.push(rowObj);
                        }
                        this.loading = false;
                    }
                });
            },
            listPage(event) {
                var page = event.page;
                console.log(page);
                this.loadData(page + 1);
            },
            addgeneraldict(v_row) {
                var row = {
                    id: this.getNewId(),
                };
                for (var i = 0; i < v_row.cols.length; i++) {
                    row[v_row.cols[i]['name']] = '';
                }
                v_row.rows.push(row);
            },
            delgeneraldict(v_data) {
                console.log('data:');
                console.log(v_data);
                console.log('selectedRows:');
                console.log(this.selectedRows);
                if (!this.selectedRows || this.selectedRows.length == 0) {
                    MessageTip.warnmsg('请选择要删除的数据');
                    return;
                }
                this.butLoading = true;
                var generaldictsetObj = {
                    name: v_data['name'],
                    categories: v_data['categories'],
                    data: [],
                };
                for (var j = 0; j < this.selectedRows.length; j++) {
                    for (var i = 0; i < v_data.rows.length; i++) {
                        if (v_data.rows[i]['id'] == this.selectedRows[j]['id']) {
                            if (parseInt(this.selectedRows[j]['id']) > 0) {
                                for (var k = 0; k < v_data.cols.length; k++) {
                                    generaldictsetObj.data.push({
                                        rowid: v_data.rows[i]['id'],
                                        name: v_data.cols[k]['label'],
                                        colname: v_data.cols[k]['name'],
                                        colvalue: v_data.rows[i][v_data.cols[k]['name']]
                                    });
                                }
                            }
                            v_data.rows.splice(i, 1);
                            break;
                        }
                    }
                }
                if (generaldictsetObj.data.length > 0) {
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem(
                            "appkey"),
                        'delete{generaldictset(o:' + JSON.stringify(generaldictsetObj) +
                        '){name categories data {rowid name colname colvalue}}}'
                    ).then(res => {
                        this.butLoading = false;
                        if (res.errcode == "0") {
                            this.selectedRows = null;
                            MessageTip.successmsg('删除成功');
                        }
                    });
                } else {
                    this.selectedRows = null;
                    this.butLoading = false;
                }
            },
            onRowEditSave(event, v_data) {
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            savegeneraldict(v_data) {
                this.butLoading = true;
                setTimeout(() => {
                    this.savegeneraldictSyn(v_data);
                }, 50);
            },
            savegeneraldictSyn(v_data) {
                var generaldictsetObj = {
                    name: v_data['name'],
                    categories: v_data['categories'],
                    data: [],
                };
                for (var i = 0; i < v_data.rows.length; i++) {
                    for (var j = 0; j < v_data.cols.length; j++) {
                        generaldictsetObj.data.push({
                            rowid: v_data.rows[i]['id'],
                            name: v_data.cols[j]['label'],
                            colname: v_data.cols[j]['name'],
                            colvalue: v_data.rows[i][v_data.cols[j]['name']]
                        });
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem(
                        "appkey"),
                    'mutation{generaldictset(o:' + JSON.stringify(generaldictsetObj) +
                    '){name categories data {rowid name colname colvalue}}}'
                ).then(res => {
                    this.butLoading = false;
                    if (res.errcode == "0") {
                        this.loadData(1);
                        MessageTip.successmsg('保存成功');
                    }
                });
            },
            cellEdit() {
                console.log('cellEdit');
                setTimeout(() => {
                    if (document.getElementById("input2"))
                        document.getElementById("input2").focus();
                }, 50);
            },
            cellEditComplete(event, v_alldata) {
                console.log("event:");
                console.log(event);
                console.log("v_alldata:");
                console.log(v_alldata);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            Editor,
        }
    }
</script>
<style lang="scss" scoped>
    .orders-subtable {
        padding: 1rem;
    }
</style>